<template>

  <div style="width: 100%">
      <div style="text-align: center;padding: 1rem 0">
        <div>
          <van-image
              class="loading-class"
              radius="10"
              width="118"
              height="118"
              :show-loading="false"
              :src="sysConf.logo"
          />
        </div>
        <div style="font-size: 18px">{{ sysConf.name }}</div>
      </div>
      <van-cell-group>
        <van-cell :title="$t('用户使用协议')" is-link :url="yhxy" />
        <van-cell :title="$t('隐私保护协议')" is-link :url="ysxy" />
<!--        <van-cell title="官方网站" is-link :url="sysConf.mobileWebsite" />-->
      </van-cell-group>
      <div style="text-align: center;margin: 0.5rem 0;color: #576b95;font-size: 0.35rem;position: absolute;bottom: 0px;width: 100%">
        {{ this.sysConf.companyName }}
      </div>
  </div>


</template>

<script>
import {Cell, CellGroup, Image} from "vant"
import {getSysConf} from "@/api/system"
import {getSaasId, setSaasId} from "@/util/session-storage-utils";
import {setToken} from "@/util/auth";
export default {
  name: "index",
  components:{
    [Image.name]:Image,
    [CellGroup.name]:CellGroup,
    [Cell.name]:Cell
  },
  computed:{
    yhxy(){
      return "https://api.youshusoft.com/gpscloud/saas/application/conf/yhxy/" + this.sysConf.id;
    },
    ysxy(){
      return "https://api.youshusoft.com/gpscloud/saas/application/conf/ysxy/" + this.sysConf.id;
    }
  },
  data(){
    return {
      sysConf:{},
      loading:true
    }
  },
  created() {
    setToken(this.$route.query._token)
    let saasId = this.$route.query._saasId;
    setSaasId(saasId)
    getSysConf(getSaasId()).then(res => {
      if(res && res.data){
      this.sysConf = res.data
    }
    }).finally(()=>{this.loading = false})
  }
}
</script>

<style lang="less">
body {
  background-color: #f2f3f5;
}
.loading-class{
  .van-image__img{
    background-color:transparent;
  }

}
</style>
